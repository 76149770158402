/* eslint-disable jsx-a11y/anchor-is-valid */
import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import {
  MenuIcon,
  XIcon,
} from '@heroicons/react/outline'

const litepaper_link = "assets/lava_litepaper_v0_1.pdf"

const navigation = [
  { name: 'Home', href: '#', target: "_self" },
  { name: 'Blog', href: 'https://blog.lavanet.xyz/', target: "_blank" },
  { name: 'Litepaper', href: litepaper_link, target: "_blank" },
  { name: 'Careers', href: 'https://lavanet.applytojob.com/apply', target: "_blank" },
]

const social = [
  {
    name: 'Twitter',
    href: 'https://twitter.com/lavanetxyz',
    icon: (props) => (
      <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
        <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
      </svg>
    ),
  },
  /*{
    name: 'GitHub',
    href: '#',
    icon: (props) => (
      <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
        <path
          fillRule="evenodd"
          d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z"
          clipRule="evenodd"
        />
      </svg>
    ),
  },*/
  {
    name: 'Discord',
    href: 'https://discord.gg/5VcqgwMmkA',
    icon: (props) => (
      <svg viewBox="0 0 71 55" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <g clipPath="url(#clip0)">
          <path d="M60.1045 4.8978C55.5792 2.8214 50.7265 1.2916 45.6527 0.41542C45.5603 0.39851 45.468 0.440769 45.4204 0.525289C44.7963 1.6353 44.105 3.0834 43.6209 4.2216C38.1637 3.4046 32.7345 3.4046 27.3892 4.2216C26.905 3.0581 26.1886 1.6353 25.5617 0.525289C25.5141 0.443589 25.4218 0.40133 25.3294 0.41542C20.2584 1.2888 15.4057 2.8186 10.8776 4.8978C10.8384 4.9147 10.8048 4.9429 10.7825 4.9795C1.57795 18.7309 -0.943561 32.1443 0.293408 45.3914C0.299005 45.4562 0.335386 45.5182 0.385761 45.5576C6.45866 50.0174 12.3413 52.7249 18.1147 54.5195C18.2071 54.5477 18.305 54.5139 18.3638 54.4378C19.7295 52.5728 20.9469 50.6063 21.9907 48.5383C22.0523 48.4172 21.9935 48.2735 21.8676 48.2256C19.9366 47.4931 18.0979 46.6 16.3292 45.5858C16.1893 45.5041 16.1781 45.304 16.3068 45.2082C16.679 44.9293 17.0513 44.6391 17.4067 44.3461C17.471 44.2926 17.5606 44.2813 17.6362 44.3151C29.2558 49.6202 41.8354 49.6202 53.3179 44.3151C53.3935 44.2785 53.4831 44.2898 53.5502 44.3433C53.9057 44.6363 54.2779 44.9293 54.6529 45.2082C54.7816 45.304 54.7732 45.5041 54.6333 45.5858C52.8646 46.6197 51.0259 47.4931 49.0921 48.2228C48.9662 48.2707 48.9102 48.4172 48.9718 48.5383C50.038 50.6034 51.2554 52.5699 52.5959 54.435C52.6519 54.5139 52.7526 54.5477 52.845 54.5195C58.6464 52.7249 64.529 50.0174 70.6019 45.5576C70.6551 45.5182 70.6887 45.459 70.6943 45.3942C72.1747 30.0791 68.2147 16.7757 60.1968 4.9823C60.1772 4.9429 60.1437 4.9147 60.1045 4.8978ZM23.7259 37.3253C20.2276 37.3253 17.3451 34.1136 17.3451 30.1693C17.3451 26.225 20.1717 23.0133 23.7259 23.0133C27.308 23.0133 30.1626 26.2532 30.1066 30.1693C30.1066 34.1136 27.28 37.3253 23.7259 37.3253ZM47.3178 37.3253C43.8196 37.3253 40.9371 34.1136 40.9371 30.1693C40.9371 26.225 43.7636 23.0133 47.3178 23.0133C50.9 23.0133 53.7545 26.2532 53.6986 30.1693C53.6986 34.1136 50.9 37.3253 47.3178 37.3253Z" fill="currentColor" />
        </g>
        <clipPath id="clip0">
          <rect width="71" height="55" fill="currentColor" />
        </clipPath>
      </svg>
    ),
  },
];


const features = [
  {
    name: 'Scalable',
    description: 'Lava optimizes for high throughput and efficiency at scale, while cryptoeconomic incentives ensure node runners provide excellent service - always.',
    icon: "assets/crystals.png",
    alt: "Lava lock",
  },
  {
    name: 'Private',
    description: 'Privacy is a core principle of the network. Our unique Application-Provider pairing mechanism ensures that state queries are optimized for privacy by design.',
    icon: "assets/lock1.png",
    alt: "Lava lock",
  },
  {
    name: 'Uncensored',
    description: 'The network aims for credible neutrality, with an open source protocol and permissionless access. RPC service is peer-to-peer, without the need for trusted intermediaries.',
    icon: "assets/cartrail.png",
    alt: "One good track",
  },
]

const features2 = [
  {
    name: 'Enterprise-grade reliability',
    description: 'Lava provides world-class infrastructure for institutional applications, with an emphasis on security, uptime and reliability.',
    icon: "assets/helmet.png",
    alt: "Miner's helmet",
  },
  {
    name: 'No procurement team needed',
    description: 'Applications and node runners can effortlessly onboard to the open-source protocol, buying and selling data permissionlessly.',
    icon: "assets/pickaxe.png",
    alt: "Pickaxe",
  },
  {
    name: 'Chain agnostic',
    description: 'Lava is a globally distributed, open middleware protocol for Web3. Chain support is only limited by the specifications offered by Providers on the network.',
    icon: "assets/cloud.png",
    alt: "Cloud",
  },
]
const logos = [
  { name: 'Tribe Capital', href: 'logos/tribe.svg' },
  { name: 'Jump Capital', href: 'logos/jump.svg' },
  { name: 'Node Capital', href: 'logos/node.svg' },
  { name: 'North Island Ventures', href: 'logos/niv.svg' },
  { name: 'Alliance', href: 'logos/alliance.svg' },
  { name: 'Quiet', href: 'logos/quiet.svg' },
  { name: 'AlphaLab', href: 'logos/alphalab.svg' },
  { name: 'Galileo', href: 'logos/galileo.png' },
  { name: 'Chainlayer', href: 'logos/chainlayer.png' },
  { name: 'Hashkey', href: 'logos/hashkey.png' },
  { name: 'Protocol Labs', href: 'logos/protocollabs.svg' },
  { name: 'Finality Capital Partners', href: 'logos/finality.png' },
]

export default function Example() {
  return (
    <div className="bg-rocks">
      <div className="relative overflow-hidden">
        <main>
          {/* Banner */}
          <div className="relative">
            <div className="absolute inset-x-0 bottom-0 h-1/2"></div>
            <div className="relative shadow-xl sm:overflow-hidden">
              <div className="absolute inset-0">
                <img className="h-full w-full object-cover 2xl:object-top opacity-50 lg:opacity-100" src="assets/banner.png" alt="" />
                <div className="absolute inset-0 bg-gradient-to-l from-transparent to-rocks opacity-90"></div>
              </div>
              <div className="absolute inset-0 block 2xl:hidden">
                <img className="h-full w-full object-bottom" src="assets/rocks.png" alt="" />
                <div className="hidden 2xl:block absolute inset-x-0 bottom-0 h-1/2 bg-gradient-to-b from-transparent via-transparent to-rocks opacity-100"></div>
              </div>
              <div className="hidden absolute inset-0 2xl:block">
                <div className="bg-bottom h-full bg-repeat-x" style={{ backgroundImage: "url('assets/rocks_low.png" }} />
                <div className="absolute inset-x-0 bottom-0 h-1/2 bg-gradient-to-b from-transparent via-transparent to-rocks opacity-100"></div>
              </div>

              <div className="relative">
                <Popover as="header" className="relative">
                  <div className="pt-6">
                    <nav
                      className="relative max-w-7xl mx-auto flex items-center justify-between px-4 sm:px-6"
                      aria-label="Global"
                    >
                      <div className="flex items-center flex-1">
                        <div className="flex items-center justify-between w-full md:w-auto">
                          <a href="#">
                            <span className="sr-only">Workflow</span>
                            <img
                              className="h-8 w-auto sm:h-10"
                              src="icon.svg"
                              alt=""
                            />
                          </a>
                          <div className="-mr-2 flex items-center md:hidden">
                            <Popover.Button className="bg-gray-900 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:bg-gray-800 focus:outline-none focus:ring-2 focus-ring-inset focus:ring-lava-500">
                              <span className="sr-only">Open main menu</span>
                              <MenuIcon className="h-6 w-6" aria-hidden="true" />
                            </Popover.Button>
                          </div>
                        </div>
                        <div className="hidden space-x-8 md:flex md:ml-10">
                          {navigation.map((item) => (
                            <a
                              key={item.name}
                              href={item.href}
                              target={item.target}
                              className="text-base font-medium text-white hover:text-gray-300"
                            >
                              {item.name}
                            </a>
                          ))}
                        </div>
                      </div>
                      <div className="hidden md:flex md:items-center md:space-x-6">
                        {social.map((item) => (
                          <a key={item.name} href={item.href} target="_blank" rel="noreferrer" className="text-white hover:text-gray-400">
                            <span className="sr-only">{item.name}</span>
                            <item.icon className="h-6 w-6" aria-hidden="true" />
                          </a>
                        ))}
                      </div>
                    </nav>
                  </div>

                  <Transition
                    as={Fragment}
                    enter="duration-150 ease-out"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="duration-100 ease-in"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                  >
                    <Popover.Panel focus className="absolute top-0 inset-x-0 p-2 transition transform origin-top md:hidden">
                      <div className="rounded-lg shadow-md bg-zinc-900 ring-1 ring-black ring-opacity-5 overflow-hidden">
                        <div className="px-5 pt-4 flex items-center justify-between">
                          <div>
                            <img
                              className="h-8 w-auto"
                              src="icon.svg"
                              alt=""
                            />
                          </div>
                          <div className="-mr-2">
                            <Popover.Button
                              className="bg-lava-600 rounded-md p-2 inline-flex items-center justify-center text-white hover:bg-lava-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-lava-400">
                              <span className="sr-only">Close menu</span>
                              <XIcon className="h-6 w-6" aria-hidden="true" />
                            </Popover.Button>
                          </div>
                        </div>
                        <div className="pt-5 pb-6">
                          <div className="px-2 space-y-1">
                            {navigation.map((item) => (
                              <a
                                key={item.name}
                                href={item.href}
                                target={item.target}
                                className="block px-3 py-2 rounded-md text-base font-medium text-white hover:bg-lava-600"
                              >
                                {item.name}
                              </a>
                            ))}
                          </div>
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </Popover>

                <div className="pt-10 sm:pt-16 lg:pt-8 lg:pb-14 lg:overflow-hidden">
                  <div className="mx-auto max-w-7xl lg:px-8">
                    <div className="lg:grid lg:grid-cols-2 lg:gap-8">
                      <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 sm:text-center lg:px-0 lg:text-left lg:flex lg:items-center">
                        <div className="lg:py-24">
                          <h1 className="mt-4 text-4xl tracking-tight font-bold text-white sm:mt-5 sm:text-6xl sm:tracking-tight lg:mt-6 xl:text-6xl xl:tracking-tight">
                            <span className="block">Peer-to-peer</span>
                            <span className="pb-3 block bg-clip-text text-transparent bg-gradient-to-r from-lava-200 via-lava-400 to-lava-600 sm:pb-5">
                              RPC network
                            </span>
                          </h1>
                          <p className="pt-2 sm:pt-0 text-gray-300 sm:text-xl lg:text-lg xl:text-xl">
                            Lava pairs Providers with Applications for scalable, private and uncensored access to Web3.
                          </p>
                          <div className="mt-6 pb-16 sm:pb-0 sm:mt-8 space-x-0 sm:space-x-4">
                            <a
                              href={litepaper_link}
                              target="_blank"
                              rel="noreferrer"
                              className="mb-2 sm:mb-0
                              w-full sm:w-auto block text-center sm:inline-block sm:px-4
                              py-2 rounded-md shadow bg-gradient-to-r from-lava-600 to-lava-500 text-base font-medium text-white hover:to-lava-400 focus:outline-none focus:ring-2 focus:ring-lava-500 focus:ring-offset-2 focus:ring-offset-lava-600"
                            >
                              Read Litepaper
                            </a>
                            <a
                              href="https://docs.google.com/forms/d/e/1FAIpQLSd-uRGAInJ6hhdt-354lW2sgY5O_o3m7aigU7-1f6egE1e5Zw/viewform"
                              target="_blank"
                              rel="noreferrer"
                              className="
                              w-full sm:w-auto block text-center sm:inline-block sm:px-4
                              py-2 rounded-md shadow font-medium text-rocks
                              xl:focus:outline-none focus:ring-2 focus:ring-lava-500 focus:ring-offset-2 focus:ring-offset-lava-600
                              bg-gradient-to-r from-lava-400 to-lava-100 hover:to-lava-200
                              xl:bg-none xl:text-white
                              xl:border-2 xl:border-lava-500 xl:hover:to-lava-400
                              "
                            >
                              Join Testnet
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Logos */}
          <div className="max-w-6xl mx-auto pb-16 pt-20 px-4 sm:px-6 lg:px-8 opacity-75">
            <div className="text-center text-white opacity-60">
              Backed By
            </div>
            <div className="mt-4 grid grid-cols-4  gap-8 text-center md:grid-cols-6 lg:grid-cols-6">
              {logos.map((logo) => (
                <div key={logo.name} className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
                  <img className="h-8 sm:h-10 object-scale-down opacity-60" src={logo.href} alt={logo.name} />
                </div>
              ))}
            </div>
          </div>

          {/* Offset 2x2 Grid*/}
          <div className="relative bg-rocks py-16 sm:py-24">
            <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
              <h2 className="text-lg font-semibold text-lava-300">Build Web3 the right way</h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl sm:tracking-tight">
                Our vision for blockchain access
              </p>
              <div className="mt-12">
                <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
                  {features.map((feature) => (
                    <div key={feature.name} className="pt-14">
                      <div className="flow-root rounded-lg bg-gradient-to-b from-lava-700 to-rocks px-6 pb-8">
                        <div className="-mt-14">
                          <img className="h-32 mx-auto" src={feature.icon} alt={feature.alt} />
                          <h3 className="mt-8 text-lg font-medium tracking-tight text-white">{feature.name}</h3>
                          <p className="mt-5 text-base text-lava-200">{feature.description}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {/* Alternating with optional testimonial */}

          <div className="relative bg-rocks py-16 overflow-hidden px-4 sm:px-6 max-w-md sm:max-w-3xl lg:max-w-7xl mx-auto">
            <div className="mx-auto pb-16 max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
              <h2 className="text-lg font-semibold text-lava-300">How it works</h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl sm:tracking-tight">
                Permissionless market for RPC data
              </p>

            </div>

            <div className="mx-auto max-w-5xl grid grid-cols-2 gap-6 sm:gap-24 items-center pb-16">
              <div>
                <span className="h-12 w-12 rounded-md flex items-center justify-center bg-gradient-to-b from-rocks to-lava-700">
                  <img className="h-8 w-6" src="assets/crystal.png" alt="Lava Crystal" />
                </span>
                <div className="mt-6">
                  <h2 className="text-3xl font-bold tracking-tight text-white">Dynamic pairing with 100% uptime</h2>
                  <p className="mt-4 text-lg text-lava-200">
                    Applications and Providers are regularly paired by service type, stake and geography to minimize latency in response times and maximize uptime.
                  </p>
                </div>
              </div>
              <div className="mx-auto">
                <div>
                  <img
                    className="h-40 sm:h-60"
                    src="assets/minercart.png"
                    alt=""
                  />
                </div>
              </div>
            </div>

            <div className="mx-auto max-w-5xl grid grid-cols-2 gap-6 sm:gap-24 items-center pb-16">
              <div className="mx-auto">
                <div>
                  <img
                    className="h-40 sm:h-60"
                    src="assets/minerinspect.png"
                    alt=""
                  />
                </div>
              </div>
              <div>
                <span className="h-12 w-12 rounded-md flex items-center justify-center bg-gradient-to-b from-rocks to-lava-700">
                  <img className="h-8 w-6" src="assets/crystal.png" alt="Lava Crystal" />
                </span>
                <div className="mt-6">
                  <h2 className="text-3xl font-bold tracking-tight text-white">Consensus around data integrity</h2>
                  <p className="mt-4 text-lg text-lava-200">
                    Eliminate the cost of cross-referencing multiple Providers - the protocol uses probabilistic sampling and consensus mechanisms to detect and resolve conflicts.
                  </p>
                </div>
              </div>
            </div>

            <div className="mx-auto max-w-5xl grid grid-cols-2 gap-6 sm:gap-24 items-center pb-16">
              <div>
                <span className="h-12 w-12 rounded-md flex items-center justify-center bg-gradient-to-b from-rocks to-lava-700">
                  <img className="h-8 w-6" src="assets/crystal.png" alt="Lava Crystal" />
                </span>
                <div className="mt-6">
                  <h2 className="text-3xl font-bold tracking-tight text-white">Tailor your RPC needs</h2>
                  <p className="mt-4 text-lg text-lava-200">
                    Applications personalize service by scoring Providers optimizing across latency, availability and data freshness.
                  </p>
                </div>
              </div>
              <div className="mx-auto">
                <div>
                  <img
                    className="h-28 sm:h-48"
                    src="assets/threecarts.png"
                    alt=""
                  />
                </div>
              </div>
            </div>

            <div className="mx-auto max-w-5xl grid grid-cols-2 gap-6 sm:gap-24 items-center">
              <div className="mx-auto">
                <div>
                  <img
                    className="h-28 sm:h-48"
                    src="assets/moneybag.png"
                    alt=""
                  />
                </div>
              </div>
              <div>
                <span className="h-12 w-12 rounded-md flex items-center justify-center bg-gradient-to-b from-rocks to-lava-700">
                  <img className="h-8 w-6" src="assets/crystal.png" alt="Lava Crystal" />
                </span>
                <div className="mt-6">
                  <h2 className="text-3xl font-bold tracking-tight text-white">Scale your usage</h2>
                  <p className="mt-4 text-lg text-lava-200">
                    The network incentivizes huge node redundancy, so that your RPC usage scales infinitely with your users.
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Centered card with graphic */}
          <div className="bg-rocks relative pt-16 mb-8">
            <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
              <div className="relative rounded-2xl px-6 py-10 bg-lava-700 overflow-hidden shadow-xl sm:px-12 sm:py-20">
                <div aria-hidden="true" className="absolute inset-0 -mt-72 sm:-mt-32 md:mt-0">
                  <svg
                    className="absolute inset-0 h-full w-full"
                    preserveAspectRatio="xMidYMid slice"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 1463 360"
                  >
                    <path
                      className="text-lava-600 text-opacity-40"
                      fill="currentColor"
                      d="M-82.673 72l1761.849 472.086-134.327 501.315-1761.85-472.086z"
                    />
                    <path
                      className="text-lava-600 text-opacity-40"
                      fill="currentColor"
                      d="M-217.088 544.086L1544.761 72l134.327 501.316-1761.849 472.086z"
                    />
                  </svg>
                </div>
                <div className="relative">
                  <div className="sm:text-center">
                    <h2 className="text-3xl font-bold text-white tracking-tight sm:text-4xl sm:tracking-tight">
                      Become a Lava node runner. Decentralize web3.
                    </h2>
                    <p className="my-6 mx-auto max-w-2xl text-lg text-lava-200">
                      Join the Lava public beta as a node runner and earn rewards for contributing to the open standard for blockchain RPC access.
                    </p>
                  </div>
                  <div className="sm:text-center mt-12">
                    <a
                      href={litepaper_link}
                      target="_blank"
                      rel="noreferrer"
                      className="sm:text-center sm:mx-auto sm:px-10 rounded-md border border-transparent px-5 py-3 bg-gradient-to-r from-lava-600 to-lava-500 text-base font-medium text-white shadow hover:to-lava-400 focus:outline-none focus:ring-2 focus:ring-lava-500 focus:ring-offset-2 focus:ring-offset-lava-600"
                    >
                      Read Litepaper
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* */}
          <div className="max-w-md mx-auto py-16 mb-8 px-4 sm:px-6 lg:grid lg:grid-cols-3 lg:gap-x-8 lg:py-24 lg:px-8 sm:max-w-3xl lg:max-w-7xl">
            <div>
              <h2 className="text-lg font-semibold text-lava-300">Join our global community</h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-white">Decentralized and robust infrastructure, for all apps</p>
              <p className="mt-4 text-lg text-lava-200">
                Dapps, wallets, games, bridges - Lava provides service across Web3
              </p>
            </div>
            <div className="mt-12 lg:col-span-2 lg:mt-0">
              <dl className="space-y-10 sm:grid sm:grid-flow-col sm:grid-cols-1 sm:grid-rows-3 sm:gap-x-6 sm:gap-y-10 sm:space-y-0 lg:gap-x-8">
                {features2.map((feature) => (
                  <div key={feature.name} className="relative">
                    <dt>
                      <img className="absolute w-16" src={feature.icon} alt={feature.alt} />
                      <p className="ml-20 text-lg font-medium leading-6 text-white">{feature.name}</p>
                    </dt>
                    <dd className="mt-2 ml-20 text-base text-lava-200">{feature.description}</dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>

          {/* Brand panel with app screenshot */}
          <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
            <div className="bg-gradient-to-b from-lava-700 to-rocks rounded-2xl shadow-xl overflow-hidden lg:grid lg:grid-cols-2 lg:gap-4">
              <div className="flex pt-10 pb-12 px-6 sm:pt-16 sm:px-16 lg:py-16 lg:pr-0 xl:py-20 xl:px-20">
                <div className="lg:self-center">
                  <h2 className="text-3xl tracking-tight font-bold text-white sm:text-4xl sm:tracking-tight">
                    <span className="block">Access Lava, access web3.</span>
                  </h2>
                  <p className="mt-4 text-lg leading-6 text-lava-200">
                  Blockchain access must be open to all. The protocol delivers on the foundational values of Web3, while providing highly robust and reliable performance. Let's scale Web3 together.
                  </p>
                  <a
                    href="https://discord.gg/5VcqgwMmkA"
                    target="_blank"
                    rel="noreferrer"
                    className="mt-8 bg-white border border-transparent rounded-md shadow px-5 py-3 inline-flex items-center text-base font-medium bg-gradient-to-r from-lava-600 to-lava-500 text-white hover:to-lava-400 focus:outline-none focus:ring-2 focus:ring-lava-500 focus:ring-offset-2 focus:ring-offset-lava-600"
                  >
                    Join Discord
                  </a>
                </div>
              </div>
              <div className="mx-auto my-auto hidden lg:block">
                <img
                  className="h-80"
                  src="assets/cartrail.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </main>

        <footer className="bg-rocks mt-12" aria-labelledby="footer-heading">
          <div className="max-w-7xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8">
            <nav className="-mx-5 -my-2 flex flex-wrap justify-center" aria-label="Footer">
              {navigation.map((item) => (
                <div key={item.name} className="px-5 py-2">
                  <a href={item.href} target={item.target} className="text-base text-gray-400 hover:text-gray-200">
                    {item.name}
                  </a>
                </div>
              ))}
            </nav>
            <div className="mt-8 flex justify-center space-x-6">
              {social.map((item) => (
                <a key={item.name} href={item.href} target="_blank" rel="noreferrer" className="text-gray-400 hover:text-gray-200">
                  <span className="sr-only">{item.name}</span>
                  <item.icon className="h-6 w-6" aria-hidden="true" />
                </a>
              ))}
            </div>

            <div className="max-w-7xl mr-10">
              <img className="mx-auto w-[320px]" src="assets/machine.webp" alt="Lava machine building blocks" />
            </div>

            <p className="mt-8 text-center text-base text-gray-400">&copy; 2022 Lava Protocol Inc. All rights reserved.</p>
          </div>
        </footer>
      </div>
    </div>
  )
}
